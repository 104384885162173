export function useMetaUtils() {
  const { upload } = useUploadUtils()

  const firstIsHero = (page: any) => {
    return page?.content?.[0]?.__component === 'sections.hero'
      ? page?.content?.[0]
      : false
  }

  const truncateStringByLength = (string: string, length: number) => {
    if (string?.length > length) {
      return string.substring(0, length - 3) + '...'
    } else {
      return string
    }
  }

  function generateMeta(item: any) {
    const title = item?.meta?.title || item?.title || item?.title || item?.slug

    const facebook = item?.meta?.socials.find(
      (social: any) => social.type === 'Facebook',
    )
    const twitter = item?.meta?.socials.find(
      (social: any) => social.type === 'Twitter',
    )

    const meta = [
      {
        hid: 'og:type',
        name: 'og:type',
        content: 'website',
      },
    ]

    if (title) {
      if (facebook) {
        meta.push({
          hid: 'og:title',
          name: 'og:title',
          content: facebook.title,
        })
      } else {
        meta.push({
          hid: 'og:title',
          name: 'og:title',
          content: title,
        })
      }
      meta.push({
        hid: 'title',
        name: 'title',
        content: title,
      })
    }

    const description =
      item?.meta?.description ||
      truncateStringByLength(item?.subtitle, 275) ||
      truncateStringByLength(item?.about?.description, 275)

    if (description) {
      meta.push({
        hid: 'og:description',
        name: 'og:description',
        content: description,
      })
      meta.push({
        hid: 'description',
        name: 'description',
        content: description,
      })
    }

    if (item?.meta) {
      const metaFields = ['robots', 'keywords']
      metaFields.forEach((metaField) => {
        const field = item?.meta?.[metaField] || null
        if (field) {
          meta.push({
            hid: metaField,
            name: metaField,
            content: field,
          })
        }
      })
    }

    const image = facebook?.image || item?.meta?.image || item?.image || null

    if (image) {
      meta.push({
        hid: 'og:image',
        name: 'og:image',
        content: upload(image),
      })
      meta.push({
        hid: 'og:image:width',
        name: 'og:image:width',
        content: image?.width,
      })
      meta.push({
        hid: 'og:image:height',
        name: 'og:image:height',
        content: image?.height,
      })
      meta.push({
        hid: 'og:image:type',
        name: 'og:image:type',
        content: image.mime,
      })
    }

    if (twitter) {
      if (twitter?.title) {
        meta.push({
          hid: 'twitter:title',
          name: 'twitter:title',
          content: twitter.title,
        })
      }

      if (twitter?.description) {
        meta.push({
          hid: 'twitter:description',
          name: 'twitter:description',
          content: twitter.description,
        })
      }

      const twitterImage = twitter?.image || null

      if (twitterImage) {
        meta.push({
          hid: 'twitter:image',
          name: 'twitter:image',
          content: upload(twitterImage),
        })
      }
    }

    const link = []

    const canonical = item?.meta?.canonicalURL || null
    if (canonical) {
      link.push({
        hid: 'i18n-can',
        rel: 'canonical',
        href: canonical,
      })
    }

    const hasHero = firstIsHero(item)
    if (hasHero) {
      if (
        hasHero?.visual?.file &&
        hasHero?.visual?.file?.data?.mime.includes('image') &&
        !hasHero?.visual?.file?.data?.ext.endsWith('svg')
      ) {
        const sizes = ['large']

        sizes.forEach((size) => {
          const image = upload(hasHero?.visual?.file, size)

          link.push({
            rel: 'preload',
            href: image,
            fetchpriority: 'high',
            as: 'image',
            type: image.endsWith('webp')
              ? 'image/webp'
              : hasHero?.visual?.file?.data?.mime,
          })
        })
      }
    }

    const script = []
    const structuredData = item?.meta?.structuredData || null
    if (structuredData) {
      script.push({
        type: 'application/ld+json',
        innerHTML: JSON.stringify(structuredData),
      })
    }

    return {
      title,
      meta,
      link,
      script,
    }
  }

  function generateBreadcrumbs(item: any) {
    const breadcrumbs = []
    let currentItem = item

    while (currentItem) {
      breadcrumbs.push({ title: currentItem.title, slug: currentItem.slug })
      currentItem = currentItem?.parent
    }

    const reversedBreadcrumbs = breadcrumbs.reverse()

    return reversedBreadcrumbs.map((crumb, index) => {
      let link = `/${crumb.slug}`
      if (index > 0) {
        link = `/${reversedBreadcrumbs[index - 1]?.slug}/${crumb?.slug}`
      }

      return {
        title: crumb.title,
        slug: crumb.slug,
        link,
      }
    })
  }

  function generateUrl(item: any) {
    const breadcrumbs = generateBreadcrumbs(item)
    const last = breadcrumbs[breadcrumbs.length - 1]

    return last?.link
  }

  return {
    generateMeta,
    generateBreadcrumbs,
    generateUrl,
  }
}
